import React from "react";
import Pokitpal from '../images/home-page/logo/pokitpal-logo.png';

const TrustedClients = (props) => {
  return (
    <div className="container">
        <div className="row">
            <div className="col-12 col-md-6 col-lg-6 p-0">
                <div className="video-out">
                    <div className="video-out-res">
                        <iframe
                        max-width="100%"
                        height="100%"
                        src={`https://www.youtube.com/embed/SeCyhu2q4uc?si=QnQKxAEc4O_L2w55`}
                        title="video-screen"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        ></iframe>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
                <div className="client-comment">
                    <div>
                        <p>“We started working with itelasoft six years ago, intially with some small projects around building the Pockitpal mobile app and that experience was very positive and as our business has grown over the last six years we've continued to engage at itelasoft in a variety of projects both frontend UI projects and backend projects and safely say we've also recommended itelasoft to a number of friends and fintech orginsations. They've been great in terms of finding good resources for us, offshore resources predominantly to help us build our business over the last six seven years.”
                        </p> 
                    </div>
                    <div className="client-dets">
                        <div>
                            <h6>Gary Cobain</h6>
                            <p>PokitPal</p>
                        </div>
                        <div>
                            <img className="" alt="pokitpal-logo" src={Pokitpal} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default TrustedClients;
