import React, { useState } from "react";
import Tabcmp from "./Tabcmp";
import SvgIconsSet from "../SvgIconsSet";

// const TabcmpSet = ({ tabData }) => {
//   const [activeTab, setActiveTab] = useState(0);
//   const [startIndex, setStartIndex] = useState(0);

//   const handleTabClick = (index) => {
//     setActiveTab(index);
//   };

//   const handleNext = () => {
//     if (startIndex + 4 < tabData.length) {
//       setStartIndex(startIndex + 1);
//       setActiveTab(startIndex + 1);
//     }
//   };

//   const handlePrev = () => {
//     if (startIndex > 0) {
//       setStartIndex(startIndex - 1);
//       setActiveTab(startIndex - 1);
//     }
//   };

//   return (
//     <div className="section-padding">
//         <div className="wr-tabcmpset">
//           <div className="tabcmpset">
//             <div className="tab-header">
//               <h3 className="title-1">What our clients have to say</h3>
//               <div className="tabcmpset tab-buttons">
//                 <button className="prev-btn" onClick={handlePrev}> 
//                   <span className="btn-icon">
//                     <SvgIconsSet.SideArrow />
//                   </span>
//                 </button>
//                 <button className="next-btn" onClick={handleNext}>          
//                   <span className="btn-icon">
//                     <SvgIconsSet.SideArrow />
//                   </span>
//                 </button>
//               </div>
//             </div>
//             <ul className="wrap-tabcmp-item">
//               {tabData.slice(startIndex, startIndex + 4).map(({ label, text, image }, index) => (
//                 <li
//                   key={index + startIndex}
//                   className={`tabcmp-item ${
//                     index + startIndex === activeTab ? "active" : ""
//                   }`}
//                 >
//                   <button className= "client-det-tab" onClick={() => handleTabClick(index + startIndex)}>
//                     <img className="client-img" src={image}/>
//                     <div className="client-details"> 
//                       <span className="client-name">{label}</span> 
//                       <span className="client-company">{text}</span>
//                     </div>
//                   </button>
//                 </li>
//               ))}
//             </ul>
//             <div className="tabcmp-panels">
//               {tabData.slice(startIndex, startIndex + 4).map(({ content }, index) => (
//                 <Tabcmp
//                   key={index + startIndex}
//                   label={tabData[index + startIndex].label}
//                   isActive={index + startIndex === activeTab}
//                   content={content}
//                 />
//               ))}
//             </div>
//             </div>
//             </div>
//     </div>
//   );
// };

// export default TabcmpSet;

const TabcmpSet = ({ tabData , tabNum }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleNext = () => {
    setActiveTab((activeTab + 1) % tabData.length);
  };

  const handlePrev = () => {
    setActiveTab((activeTab - 1 + tabData.length) % tabData.length);
  };

  return (
    <div className="section-padding">
        <div className="wr-tabcmpset">
          <div className="tabcmpset">
            <div className="tab-header">
              <h3 className="title-1">What our clients say</h3>
              <div className="tabcmpset tab-buttons">
                <button className="prev-btn" onClick={handlePrev}> 
                  <span className="btn-icon">
                    <SvgIconsSet.SideArrow />
                  </span>
                </button>
                <button className="next-btn" onClick={handleNext}>          
                  <span className="btn-icon">
                    <SvgIconsSet.SideArrow />
                  </span>
                </button>
              </div>
            </div>

            <ul className="wrap-tabcmp-item">
              {Array.from({ length: [tabNum] }, (_, index) => {
                const tabIdx = (activeTab + index) % tabData.length;
                const { label, text, image } = tabData[tabIdx];
                return (
                  <li
                    key={tabIdx}
                    className={`tabcmp-item ${index === 0 ? "active" : ""}`}
                  >
                    <button className="client-det-tab" onClick={() => handleTabClick(tabIdx)}>
                      <img className="client-img" src={image} alt={`Client ${label}`} />
                      <div className="client-details"> 
                        <span className="client-name">{label}</span> 
                        <span className="client-company">{text}</span>
                      </div>
                    </button>
                  </li>
                );
              })}
            </ul>
            <div className="tabcmp-panels">
              {Array.from({ length: [tabNum] }, (_, index) => {
                const tabIdx = (activeTab + index) % tabData.length;
                const { content } = tabData[tabIdx];
                return (
                  <Tabcmp
                    key={tabIdx}
                    label={tabData[tabIdx].label}
                    isActive={index === 0}
                    content={content}
                  />
                );
              })}
            </div>
          </div>
        </div>
    </div>
    
  );
};

export default TabcmpSet;