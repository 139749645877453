export const generateDownloadFileName = (filePath) => {
  const fileName = filePath.substring(filePath.lastIndexOf("/") + 1);
  const fileNameWithoutIdentifier = fileName.replace(/-\w+(\.\w+)$/, "$1");
  return fileNameWithoutIdentifier;
};

export const generateDownload = (document, fileUrls) => {
  const delayBetweenDownloads = 5000;
  fileUrls.forEach((fileUrl, index) => {
    setTimeout(() => {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", generateDownloadFileName(fileUrl));
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, index * delayBetweenDownloads);
  });
};
