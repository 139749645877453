import React from "react";
import comment from '../images/comment.png';
import { Container } from "reactstrap";

const GetInTouch = (props) => {
  return (
    <Container fluid className="p-0">
      <div className="section-padding wrap-contrast-section">
        <Container>
          <div
            className="contrast-section"
          >
            <div className="contrast-section-title">
              {props.title}
            </div>
            <div className="contrast-section-text">
              {props.para}
              <br/><br/>
              {props.para2}
            </div>
            
          </div>
        </Container>
      </div>
    </Container>
  );
};

export default GetInTouch;
