import React from "react";

import GigaClear from '../images/services/logo/gigaclear.png';
import Oxygen from '../images/services/logo/oxygen.png';
import Pokitpal from '../images/services/logo/pokitpal.png';
import Effi from '../images/services/logo/effi-logo.png';
import Beforepay from '../images/services/logo/beforepay.png';

const Whodepemdonus = () => {
  return (
    <div id="who-depend-on-us">
      {" "}
      <div className="title-1 mb-2">Who Depends on Us? </div>
      <div className="section-discription">
        Number of organisations ranging from start-ups, scale-ups, to large
        enterprises depend on iTelaSoft for Quality Engineering and Validation
        services.
      </div>
      {/* <div className="img-grid">
          <div className="card">
                <a href="https://gigaclear.com/" target="_blank">
                  <img className="img-partner" alt="Partners" src={GigaClear} />
                </a>
          </div>
          <div className="card">
            <a href="https://oxygen.com.au/" target="_blank">
              <img className="img-partner" alt="Partners" src={Oxygen} />
            </a>
          </div>
          <div className="card">
            <a href="https://pokitpal.com/" target="_blank">
              <img className="img-partner" alt="Partners" src={Pokitpal} />
            </a>
          </div>
          <div className="card">
            <a href="https://www.effi.com.au/" target="_blank">
              <img className="img-partner" alt="Partners" src={Effi} />
            </a>
          </div>
          <div className="card">
            <a href="https://www.beforepay.com.au/" target="_blank">
              <img className="img-partner" alt="Partners" src={Beforepay} />
            </a>
          </div>
          
        </div> */}
      <div className="section-our-partners-logo-set">
        <a href="https://gigaclear.com/" target="_blank">
          <div className="wrap-logo p-0">
            <div className="partners-logo">
              {" "}
              <img src={GigaClear} alt=""></img>
            </div>
            {/* <div className="logo-dec">Gigaclear</div> */}
          </div>
        </a>
        <a href="https://oxygen.com.au/" target="_blank">
          <div className="wrap-logo p-0">
            <div className="partners-logo">
              {" "}
              <img src={Oxygen} alt=""></img>
            </div>
            {/* <div className="logo-dec">Oxygen</div> */}
          </div>
        </a>
        <a href="https://www.pokitpal.com/" target="_blank">
          <div className="wrap-logo p-0">
            <div className="partners-logo">
              {" "}
              <img src={Pokitpal} alt=""></img>
            </div>
            {/* <div className="logo-dec">PokitPal</div> */}
          </div>
        </a>
        <a href="https://effi.com.au/" target="_blank">
          <div className="wrap-logo p-0">
            <div className="partners-logo">
              {" "}
              <img src={Effi} alt=""></img>
            </div>
            {/* <div className="logo-dec">Effi</div> */}
          </div>
        </a>
        <a href="https://www.beforepay.com.au/" target="_blank">
          <div className="wrap-logo p-0">
            <div className="partners-logo">
              {" "}
              <img src={Beforepay} alt=""></img>
            </div>
            {/* <div className="logo-dec">Beforepay</div> */}
          </div>
        </a>
      </div>
    </div>
  );
};

export default Whodepemdonus;
