import React from "react";
import Oxygen from '../images/home-page/logo/oxygen-logo.png';

const TrustedClients = (props) => {
  return (
    <div className="container">
        <div className="row">
            <div className="col-12 col-md-6 col-lg-6 p-0">
                <div className="video-out">
                    <div className="video-out-res">
                        <iframe
                        max-width="100%"
                        height="100%"
                        src={'https://www.youtube.com/embed/3FHp6X4N8YQ?si=Thm9BmCoXFwS5rqf'}
                        title="video-screen"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        ></iframe>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
                <div className="client-comment">
                    <div>
                        <p>“We've been using iTelasoft for the last two years and they've truly become a trusted partner of ours and are now doing almost all of our I.T. development and integration work. In my 25 years of experience in financial services and digital automation, I rate iTelaSoft as by far the best integration and software development partner I've ever used in my working career.”
                        </p> 
                    </div>
                    <div className="client-dets">
                        <div>
                            <h6>Ben Taylor</h6>
                            <p>Oxygen Broking Services</p>
                        </div>
                        <div>
                            <img className="" alt="oxygen-logo" src={Oxygen} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default TrustedClients;
