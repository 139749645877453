import React from 'react';

const Tabcmp = ({isActive, content }) => {
  return (
    <div className={`tabcmp ${isActive ? 'active' : ''}`}>
      {isActive && <div className="tabcmp-content">{content}</div>}
    </div>
  );
};

export default Tabcmp;
