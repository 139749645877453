import React from "react";
import { Row, Col, Container } from "reactstrap";
import { Form, Field, FormSpy } from "react-final-form";
import * as yup from "yup";
import { Button } from "react-bootstrap";
import Layout from "../../../layouts";
import Seo from "../../../components/Seo";
import IntroBanner from "../../../components/IntroBannerRevamp";
import Banner from "../../../images/services/qa/qa-test-cover.png";
import ContrastSection from "../../../components/ContrastSection";
import { sendEmail } from "../../../services/contact";
import { generateDownload } from "../../../utils/helperFunctions";

// Partner
import logoISTQB from "../../../images/services/qa/istqb-platinum.png";

// Client
import Whodepemdonus from "../../../components/WhoDependsOnUs";

// Icons
import iconBalance from "../../../images/service-icons/qa/balance-scale.svg";
import iconModern from "../../../images/service-icons/qa/modern-tools.svg";
import iconCost from "../../../images/service-icons/qa/cost-effective.svg";
import iconRisk from "../../../images/service-icons/qa/risk.svg";
import iconExperience from "../../../images/service-icons/qa/experience.svg";
import iconTools from "../../../images/service-icons/qa/build.svg";
import iconCompetency from "../../../images/service-icons/qa/competency.svg";
import iconCertified from "../../../images/service-icons/qa/certified.svg";
import iconTesting from "../../../images/service-icons/qa/testing.svg";
import iconGdpr from "../../../images/service-icons/qa/gdpr.svg";

// Testimonial
import TabcmpSet from "../../../components/tab/TabcmpSet";
import ClientTestimonial3 from "../../../components/ClientTestimonialGaryPokitpal";
import ClientTestimonial5 from "../../../components/ClientTestimonialBenOxygen";
import BenTaylor from "../../../images/Clients/BenTaylor.jpg";
import GaryCobain from "../../../images/Clients/GaryCobain.jpeg";

// Casestudy
import downloadFile from "../../../documents/Case-Study-Customised-QA-Services.pdf";
import caseStudyImg from "../../../images/casestudyimg3.jpg";

// Contact
import UserCard from "../../../components/UserCard";
import Chanakya from "../../../images/services/qa/chanakya.png";
import SvgIconsSet from "../../../components/SvgIconsSet";

const servicesList = [
  {
    id: "1",
    title: "Product Quality Engineering",
    textbold: "Developing a new product that needs to be independently tested?",
    textdetail:
      "If you are developing a tech product that needs independent, comprehensive testing, iTelaSoft’s Product Quality Engineering service provides end to end coverage. Starting with quality assessment, then quality approach, test design & execution to issue mediation and resolution, we support you with a complete product quality management framework.",
  },
  {
    id: "2",
    title: "Platform Validation",
    textbold: "Need to validate your existing software platform? ",
    textdetail:
      "A software platform is a live entity. Based on changes they go through, information they accumulate, people who access them, and vulnerabilities emerging, they evolve constantly. Corporates need to validate an existing platform from time to time to ensure it serves the right environment in the right way. iTelaSoft’s Platform Validation service offers an end-to-end assessment on usability, security, performance, vulnerabilities, and compliance.",
  },
  {
    id: "3",
    title: "Product Modernisation Verification",
    textbold: "Planning to modernise your legacy application? ",
    textdetail:
      "Inevitably, software products also come to an end of life eventually; and may get modernised as a new product. Modernisation engagements are usually complex due to new tech, new frameworks, added capabilities and changed user experience, plus backward compatibility, existing integrations, and data migrations. We provide Product Modernisation Verification service, that starts with integrated planning, verification strategy and testing.",
  },
  {
    id: "4",
    title: "Managed Test Teams",
    textbold: "Need qualified quality engineers to extend your team?",
    textdetail:
      "Many organisations, especially medium to large scale enterprises often have their own product development team. In some cases, they have quality assurance and testing professionals as well. Despite in-house capacity, there are many reasons where external support is required. Those include, additional capacity needs, tooling and domain expertise, automation, accelerating time to market, and cost reduction. iTelaSoft provide Managed Test Teams to extend your team and perform software testing faster, better.",
  },
];

const QualityAssurance = () => {
  const tabData = [
    {
      label: "Gary Cobain",
      text: "PokitPal",
      image: GaryCobain,
      content: <ClientTestimonial3 />,
    },
    {
      label: "Ben Taylor",
      text: "Oxygen Broking Services",
      image: BenTaylor,
      content: <ClientTestimonial5 />,
    },
  ];

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is a required field"),
  });

  const validate = (values) => {
    const errors = {};
    try {
      schema.validateSync(values, { abortEarly: false });
    } catch (err) {
      err.inner.forEach((error) => {
        errors[error.path] = error.message;
      });
    }
    return errors;
  };

  const onSubmitHandler = async (values) => {
    // eslint-disable-next-line no-undef
    generateDownload(document, [downloadFile]);

    const formData = { ...values };
    formData.Message = "CASE STUDY: DISCOVER THE POWER OF AI IN MORTGAGE BROKING";
    formData.FormType = "CaseStudy";
    formData.Subject = "CASE STUDY: QUALITY ASSURANCE";

    try {
      await sendEmail(formData);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  return (
    <Layout bodyClass="service-page qa-service">
      <Seo
        title="Independent Validation & Quality Assurance"
        description="iTelaSoft is an Australian-owned company providing IT services and
              software product development.. We specialise in strategy
              consultation, design, development and management of products. We
              help entrepreneurs transform ground breaking ideas into successful
              solutions."
      />

      <IntroBanner
        title="Independent Validation & Quality Assurance"
        subtitle="Ensure your product is fit for purpose and comply with user expectations"
        image={Banner}
      />

      <div className="content">
        <ContrastSection
          title="The Power of Quality Engineering with iTelaSoft"
          para="Quality Engineering and Validation are crucial for the success of any software platform or tech product. These practices ensure customer satisfaction, maintain brand reputation, achieve cost savings, comply with regulations, and gain a competitive advantage. Whether you have an existing software platform that needs improvement or starting to build a new one from scratch, iTelaSoft can provide the necessary support and expertise for your software testing needs."
          para2="Our team can help you modernise your existing platform or build a new tech product, ensuring that it meets the highest quality standards and exceeds customer expectations. By partnering with iTelaSoft, you can be confident in the reliability and performance of your software solution."
        />
      </div>

      <section className="section-our-partners">
        <Container className="industry-section">
          <Row className="mt-4 industry-partners">
            <Col>
              <div className="container pb-6 section-2">
                <div className="row">
                  <div className="col-md-6 pr-md-5">
                    <img
                      alt="istqb logo"
                      className="img-fluid"
                      src={logoISTQB}
                    />
                  </div>
                  <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-end">
                    {/* <div className="title title-1">Industry Partners</div> */}
                    <p>
                      iTelaSoft is a platinum partner of ISTQB, the
                      International Software Qualifications Board that set
                      qualification standards and testing of professionals. This
                      accreditation ensures correct capabilities and trust to
                      our customers in quality engineering needs.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container className="">
          <div className="title title-1">
            Why an Independent Partner is Important?
          </div>
          <div className="sub-title">
            Choosing an independent quality engineering and validation partner
            such as iTelaSoft offers several advantages.
            {" "}
          </div>

          <Row className="mt-2">
            <Col>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={iconBalance} alt="Icon" />
                </div>
                <div className="text">Unbiased and independent assessment</div>
              </div>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={iconModern} alt="Icon" />
                </div>
                <div className="text">
                  Access to specialised and experienced professionals with
                  modern tooling & frameworks
                </div>
              </div>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={iconCost} alt="Icon" />
                </div>
                <div className="text">
                  Flexibility, scalability & cost effectiveness
                </div>
              </div>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={iconRisk} alt="Icon" />
                </div>
                <div className="text">
                  Risk mitigation and regulatory compliance
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container className="service-container">
          <Row>
            <Col>
              <div>
                <div className="title title-1">
                  Quality Engineering Services
                </div>
                <div className="sub-title">
                  iTelaSoft provides a wide range of Quality Engineering
                  Services for different scenarios.
                  {" "}
                </div>
              </div>
              <div className="mt-4">
                {servicesList.map((service) => (
                  <div className="wrapper-service" key={service.id}>
                    <div className="service-details">
                      <div className="service-title">{service.title}</div>
                      <div className="service-content">
                        <div className="service-breif pb-1">
                          {service.textbold}
                        </div>
                        <div className="service-description">
                          {service.textdetail}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <div className="wrap-case-study mt-3">
          <Container>
            <div className="row py-6">
              <div className="col-12">
                <div className="career">
                  <div className="">
                    <div className="row justify-content-center">
                      <div className="col-12 col-lg-6 flex-column">
                        <div>
                          <h5 className="title-5 mb-0">Case Study</h5>
                          <h3 className="title-3 mt-0">
                            DISCOVER THE POWER OF AI IN MORTGAGE BROKING
                          </h3>
                          <p>
                            HOW A QA PARTNERSHIP SET GIGACLEAR UP FOR CONTINUED
                            SUCCESS
                          </p>
                          <p>
                            Discover how Gigaclear leveraged a strategic QA
                            partnership to achieve sustainable growth and
                            operational excellence. Explore their journey to
                            continued success.
                          </p>
                        </div>
                        <div className="mt-2 mb-3">
                          <p><b>Download the Case Study Now!</b></p>
                          <Form
                            onSubmit={onSubmitHandler}
                            validate={validate}
                            render={({ handleSubmit }) => (
                              <form
                                className="case-study-email-form d-flex flex-sm-row flex-column justify-content-md-between mb-1"
                                onSubmit={handleSubmit}
                              >
                                <Field name="email">
                                  {({ input, meta }) => (
                                    <div className="col-lg col-md pr-1 col-sm-7 col-12 p-0">
                                      <input
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...input}
                                        type="email"
                                        placeholder="Enter your e-mail address"
                                      />
                                      {meta.error && meta.touched && (
                                        <span className="error-val">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                                <FormSpy
                                  subscription={{
                                    hasValidationErrors: true,
                                    submitting: true,
                                  }}
                                >
                                  {(subscriptions) => (
                                    <Button
                                      className={`btn-icon-button mt-1 mt-sm-0 mb-2 ${
                                        subscriptions.hasValidationErrors
                                        || subscriptions.submitting
                                          ? "disabled-button"
                                          : ""
                                      }`}
                                      variant="primary"
                                      type="submit"
                                    >
                                      <span className="btn-text">
                                        Download Case Study
                                      </span>
                                      <span className="btn-icon">
                                        <SvgIconsSet.SideArrow />
                                      </span>
                                    </Button>
                                  )}
                                </FormSpy>
                              </form>
                            )}
                          />

                          <p className="small-text">
                            By downloading this case study, you agree to receive
                            emails from iTelaSoft about products, services, and
                            updates. You can unsubscribe at any time.
                          </p>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <img
                          alt="product innovation"
                          className="img-fluid"
                          src={caseStudyImg}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>

      <section>
        <Container>
          <div>
            <div className="title title-1">Why iTelaSoft?</div>
            <div className="sub-title">
              iTelaSoft is a full spectrum software services provider with its
              independent Quality Engineering and Validation team. There are
              many reasons why we have been a trusted testing provider for more
              than 10 years
            </div>
          </div>
          <Row className="mt-4">
            <Col md={6} lg={6}>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={iconExperience} alt="Icon" />
                </div>
                <div className="text">
                  Experience in complex applications and multiple domains
                </div>
              </div>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={iconCompetency} alt="Icon" />
                </div>
                <div className="text">
                  Test competency in web, mobile, API, IoT, and integration
                  applications
                </div>
              </div>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={iconTesting} alt="Icon" />
                </div>
                <div className="text">
                  Specialised test needs such as cellular service testing &
                  salesforce testing
                </div>
              </div>
            </Col>
            <Col md={6} lg={6}>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={iconTools} alt="Icon" />
                </div>
                <div className="text">Mature methodology and tool-set</div>
              </div>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={iconCertified} alt="Icon" />
                </div>
                <div className="text">
                  Platinum Partner with certified engineers
                </div>
              </div>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={iconGdpr} alt="Icon" />
                </div>
                <div className="text">
                  Compliance with industry standards (GDPR)
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section-our-partners">
        <Container>
          <Row>
            <Col>
              <Whodepemdonus />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="client-testimonial-section">
        <Container>
          <TabcmpSet tabData={tabData} tabNum={2} />
        </Container>
      </section>

      <section className="section-contact">
        <Container>
          <Row>
            <Col md={9} lg={9} xl={9} className="title-section flex-column">
              <div className="title title-1 desk">Let us know how to help</div>
              <div className="title title-1 mobile">
                Let us know how to help
              </div>
              <div>
                <p>
                  We love to hear what challenges you go through in your
                  business and discuss how we can assist you.
                </p>
              </div>
              <div className="getting-touch-right">
                <a className="btn btn-primary btn-icon-button" href="/contact/">
                  <span className="btn-text">Get in touch</span>
                  <span className="btn-icon">
                    <SvgIconsSet.SideArrow />
                  </span>
                </a>
              </div>
            </Col>
            <Col
              md={3}
              lg={3}
              xl={3}
              className="mb-2 contact-card-section col-6"
            >
              <a
                className="contact-card"
                href="/contact/"
                aria-label="Contact Chanakya Dharmasiri"
              >
                <UserCard
                  name="Chanakya Dharmasiri"
                  detail="Quality Engineering Adviser"
                  image={Chanakya}
                  link="https://www.linkedin.com/in/chanakya-dharmasiri/"
                />
              </a>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default QualityAssurance;
